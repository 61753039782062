import React from 'react'
import styled from 'styled-components'
import Typography from 'src/components/Typography'
import { up } from 'styled-breakpoints'

const Styled = styled.div`
  display: grid;
  grid-gap: 0;

  ${up('md')} {
    grid-template-columns: auto 1fr;
    grid-gap: 0.5em 20px;
    max-width: calc(50% - 50px);
  }
`

const Value = styled(Typography)`
  &:not(:last-child) {
    margin-bottom: 20px;
    ${up('md')} {
      margin-bottom: 0;
    }
  }
`

const StyledLink = styled.a`
  color: currentColor;

  &,
  span {
    text-decoration: underline;
  }
`

const Details = props => {
  const { values, ...passProps } = props

  return (
    <Styled {...passProps}>
      {values
        .filter(x => x.text !== '' && x.label !== '')
        .map((value, key) => {
          return (
            <React.Fragment key={key}>
              <Typography variant="detailsTable" medium>
                {value.label}
              </Typography>
              {value.link ? (
                <StyledLink href={value.link} target="_blank">
                  <Value variant="detailsTable">{value.text}</Value>
                </StyledLink>
              ) : (
                <Value variant="detailsTable">{value.text}</Value>
              )}
            </React.Fragment>
          )
        })}
    </Styled>
  )
}

Details.propTypes = {}

Details.defaultProps = {}

export default Details
