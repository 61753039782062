import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { up } from 'styled-breakpoints'

const Styled = styled.div`
  display: block;

  ${up('sm')} {
    column-count: ${({ columns }) => columns};
    column-gap: ${({ gap, theme }) => theme.spacing(gap, 'px')};
  }

  > :first-child {
    margin-top: 0;
  }

  > :last-child {
    margin-bottom: 0;
  }
`

const TextColumns = props => {
  const { children, ...passProps } = props

  return <Styled {...passProps}>{children}</Styled>
}

TextColumns.propTypes = {
  children: PropTypes.any,
  columns: PropTypes.number,
  gap: PropTypes.number,
}

TextColumns.defaultProps = {
  columns: 2,
  gap: 12.5,
}

export default TextColumns
