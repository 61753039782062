import React from 'react'
import { get } from 'lodash'
import { graphql, Link } from 'gatsby'
import Layout from 'src/layout'
import Section from 'src/components/Section'
import Hero from 'src/components/Hero'
import FlexibleLogo from 'src/components/FlexibleLogo'
import HeroContent from 'src/components/HeroContent'
import Typography from 'src/components/Typography'
import BackgroundImage from 'src/components/BackgroundImage'
import Container from 'src/components/Container'
import Box from '@material-ui/core/Box'
import Facts from 'src/components/Facts'
import Fact from 'src/components/Fact'
import TextColumns from 'src/components/TextColumns'
import Grid from 'src/components/Grid'
import Image from 'src/components/Image'
import Markdown from 'src/components/Markdown'
import Details from 'src/components/Details'
import ServiceRange from 'src/components/ServiceRange'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css' // This only needs to be imported once in your app

export default function Project(props) {
  const {
    projects: { nodes: projects },
    // stories: { nodes: stories },
  } = props.data

  const {
    cover,
    title,
    focus,
    intro,
    facts,
    images,
    services,
    awards,
    location,
    net_area,
    gross_area,
    gross_volume,
    details,
    builder,
    created_at,
    constructed_at,
    completed_at,
    contact,
    metaTitle,
    metaDescription,
    metaImage,
  } = projects[0]

  const contactProps = {
    contactTitle: focus.contactTitle,
    contactText: focus.contactText,
    contact,
  }

  const [photoIndex, setPhotoIndex] = React.useState(0)
  const [isOpen, setIsOpen] = React.useState(false)

  const metaProps = {
    metaTitle,
    metaDescription,
    metaImage,
  }

  let concatFacts = [
    {
      label: 'Bauherr',
      text: builder,
    },
    {
      label: 'Ort',
      text: location,
    },
    {
      label: 'Leistungsphasen',
      text: <ServiceRange values={services} />,
    },
    ...(net_area
      ? [
          {
            label: 'NGF',
            text: (
              <>
                {net_area} m<sup style={{ fontSize: '8px' }}>2</sup>
              </>
            ),
          },
        ]
      : []),
    ...(gross_area
      ? [
          {
            label: 'BGF',
            text: (
              <>
                {gross_area} m<sup style={{ fontSize: '8px' }}>2</sup>
              </>
            ),
          },
        ]
      : []),
    ...(gross_volume
      ? [
          {
            label: 'BRI',
            text: (
              <>
                {gross_volume} m<sup style={{ fontSize: '8px' }}>3</sup>
              </>
            ),
          },
        ]
      : []),
    ...facts,
    {
      label: 'Awards',
      text: awards.map(a => a.name).join(),
    },
  ]

  const imageSources = images
    .map(x => x.local)
    .map((entry, key) => entry && entry.childImageSharp.fluid.src)

  return (
    <Layout contact={contactProps} meta={metaProps}>
      {/*
            Hero
      */}
      <Section border={false} flush>
        <Hero>
          {cover && cover.local && (
            <BackgroundImage
              darken={0.2}
              fluid={cover.local.childImageSharp.fluid}
            />
          )}
          <FlexibleLogo color="primary" />
          <HeroContent>
            <Typography variant="heroLabel" paragraph>
              <Link to={`/schwerpunkte/${focus.slug}`}>{focus.title}</Link>
            </Typography>
            <Typography variant="hero" paragraph>
              {title}
            </Typography>
          </HeroContent>
        </Hero>
      </Section>

      {/*
            Intro
       */}
      <Section color="primary" border={false} flush>
        <Container>
          <Box maxWidth={700} mx="auto" mb={60 / 8}>
            <Typography variant="teaser">{intro}</Typography>
          </Box>
          {/* Disabled / HHA will think about which info to display here */}
          {/* <Box mx="auto">
            <Facts border={false}>
              {created_at !== '' && (
                <Fact label={'Planungsbeginn'}>
                  {created_at.replace('Invalid date', '–')}
                </Fact>
              )}
              {constructed_at !== '' && (
                <Fact label={'Baubeginn'}>
                  {constructed_at.replace('Invalid date', '–')}
                </Fact>
              )}
              {completed_at !== '' && (
                <Fact border={false} label={'Fertigstellung'}>
                  {completed_at.replace('Invalid date', '–')}
                </Fact>
              )}
            </Facts>
          </Box> */}
        </Container>
      </Section>

      {/*
          Images
      */}
      <Section border={false} flush>
        <Container>
          <Grid variant="2x">
            {images
              .map(x => x.local)
              .map(
                (entry, key) =>
                  entry && (
                    <Box
                      key={key}
                      onClick={() => {
                        setPhotoIndex(key)
                        setIsOpen(true)
                      }}
                    >
                      <Image
                        style={{ cursor: 'pointer' }}
                        fluid={entry.childImageSharp.fluid}
                      />
                    </Box>
                  )
              )}
          </Grid>
        </Container>
      </Section>

      {/*
          Lightbox
      */}

      {isOpen && (
        <Lightbox
          mainSrc={imageSources[photoIndex]}
          nextSrc={imageSources[(photoIndex + 1) % imageSources.length]}
          prevSrc={
            imageSources[
              (photoIndex + imageSources.length - 1) % imageSources.length
            ]
          }
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (photoIndex + imageSources.length - 1) % imageSources.length
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
        />
      )}

      {/*
          Details
      */}
      <Section color="secondary">
        <Container>
          <Typography variant="h1" paragraph>
            Details
          </Typography>
          <Typography variant="body" inline>
            <TextColumns>
              <Markdown source={details} />
            </TextColumns>
          </Typography>
        </Container>
        {concatFacts && concatFacts.length > 0 && (
          <Container>
            <Box mt={30 / 8}>
              <Details values={concatFacts} />
            </Box>
          </Container>
        )}
      </Section>

      {/*
          Stories
      */}
      {/*
      <Section color="secondary">
        <Container>
          <Typography variant="h1" paragraph>
            Stories
          </Typography>
          <StorySlider {...{ stories: stories }} />
        </Container>
      </Section>
      */}
    </Layout>
  )
}

export const query = graphql`
  query($id: String) {
    projects: allProjects(filter: { id: { eq: $id } }) {
      nodes {
        cover {
          local {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 60) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
        title
        builder
        services
        location
        net_area
        gross_area
        gross_volume
        awards {
          name
        }
        created_at(formatString: "MM/YYYY")
        constructed_at(formatString: "MM/YYYY")
        completed_at(formatString: "MM/YYYY")
        focus {
          title
          slug
          contactTitle
          contactText
        }
        intro
        facts {
          label
          link
          text
        }
        images {
          local {
            childImageSharp {
              fluid(maxWidth: 1920, maxHeight: 1273, quality: 60) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
        details
        contact {
          ...ContactFragment
        }
        metaTitle
        metaDescription
        metaImage {
          local {
            childImageSharp {
              fixed(width: 1024, quality: 60) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  }
`

// stories: allStories(limit: 9, filter: { id: { ne: "dummy" } }) {
//   nodes {
//     ...StorySliderFragment
//   }
// }
